/**
 * Alert Messages
 *
 */
export const Messages={
    "stock_delete":{
       "title":"Confirm",
       "message":"Are you sure to you want to delete \"{item_description}\" from warehouse \"{location_name}\" : quantity {quantity}-{unit_name} ?"
    },
    "stock_cancel_unavailable":{
       "title":"Confirm",
       "message":"Are you sure you want to release \"{item_description}\" from warehouse \"{location_name}\"  : quantity {quantity}-{unit_name} ?"
    },
    "emergency_operator_required":{
       "title":"Access denied",
       "message":"You do not have sufficient access rights for this. Contact your Administrator"
    },
    "emergency_owner_mail_sent":{
       "title":"Mail Sent",
       "message":"Email sent to {email}"
    },
    "emergency_owner_mail_not_sent":{
       "title":"Failed to Send",
       "message":"Unable to send mail to {email}"
    },
    "emergency_srf_cancel":{
       "title":"Confirm",
       "message":"Are you sure you want to cancel {srf_number}?"
    },
    "emergency_package_cancel":{
       "title":"Confirm",
       "message":"Are you sure you want to cancel {package_number}?"
    },
    "emergency_srf_edit_expired":{
       "title":"Session Expired!",
       "message":"This SRF cannot be edited"
    },
    "emergency_srf_form_warn_from":{
       "title":"Action Required",
       "message":"\"Please choose \"Ship From\" (Country and Warehouse) first\""
    },
    "emergency_srf_form_warn_sending":{
       "title":"Action Required",
       "message":"\"Please choose \"Sending Organisation\" first\""
    },
    "emergency_srf_form_error_required":{
       "title":"Error Message",
       "message":"Please enter all required fields for selected rows"
    },
    "emergency_srf_form_error_highlighted":{
       "title":"Error Message",
       "message":"Please fill in all fields highlighted in red."
    },
    "emergency_pl_form_error_qty":{
       "title":"Invalid",
       "message":"The sum of \"Accepted Qty\", \"Damaged Qty\", and \"Missing Qty\" cannot exceed the line item shipped quantity."
    },
    "emergency_pl_form_error_required":{
       "title":"Invalid",
       "message":"Please fill all required fields."
    },
    "admin_user_approve":{
       "title":"Confirm",
       "message":"Are you sure you want to approve user {name}?"
    },
    "admin_user_suspend":{
       "title":"Confirm",
       "message":"Are you sure to suspend the user {name}?"
    },
    "admin_country_delete":{
       "title":"Confirm",
       "message":"Are you sure you want to delete {name}?"
    },
    "admin_country_cannot_delete":{
       "title":"Denied",
       "message":"This country cannot be deleted, please clean data related to this country and try again"
    },
    "admin_org_approve":{
       "title":"Confirm",
       "message":"Are you sure you want to approve this organisation {name}?"
    },
    "admin_org_suspend":{
       "title":"Confirm",
       "message":"Are you sure you want to suspend this organisation {name}?"
    },
    "admin_org_delete":{
       "title":"Confirm",
       "message":"Are you sure you want to delete {name}?"
    },
    "admin_org_cannot_delete":{
       "title":"Denied",
       "message":"This organisation cannot be deleted, please clean data related to this organisation and try again"
    },
    "mobile_gps_required":{
       "title":"Warning",
       "message":"Location permission must be turned on"
    },
    "mobile_gps_error":{
       "title":"Error",
       "message":"GPS coordinates cannot be determined. Please enable GPS  in settings and Click on \"Reload GPS\" button, or type latitude and longitude manually as \"latitude,longitude\""
    },
    "mobile_recieve_deny":{
       "title":"Operation denied",
       "message":"This package has been received already."
    },
    "mobile_qr_invalid":{
       "title":"Error",
       "message":"QR code is invalid"
    },
    "mobile_qr_fail":{
       "title":"Error",
       "message":"QR code scanning failed {error}"
    },
    "mobile_gps_disabled":{
       "title":"Error",
       "message":"Location must be enabled to use this app"
    }
 };