import React, { useState } from "react";
import { Link, User, Button } from "@wfp/ui";
import { FaQrcode } from "react-icons/fa";
import Helper from "../lib/Helper";

/**
 * Main Navigation
 * @author Kyaw Zin Soe
 * @datetime 2-Apr-2022
 */

/**
 * Sidebar component
 *
 * @param {object} props
 */
const Sidebar = function (props) {
  if (props.enable) {
    /**
     * return rendered view
     *
     */
    return (
      <ul className={"sidebar " + props.navState}>
        {props.menus.map((menu, index) => {
          let mbC = !menu.mobile ? " lg-sc-only" : "";
          return (
            <span key={index}>
              {props.eUser && props.eUser["_" + menu.auth]() ? (
                <li
                  key={index}
                  className={
                    (props.route.startsWith(menu.route)
                      ? "active" + mbC
                      : mbC) +
                    " " +
                    (menu.className ? menu.className : "")
                  }
                >
                  <a
                    rel="noreferrer"
                    href={menu.link ? menu.link : menu.path}
                    target={menu.link ? "_blank" : null}
                    className="pr-vm"
                  >
                    {menu.icon} <p>{menu.title}</p>
                  </a>
                </li>
              ) : (
                <></>
              )}
            </span>
          );
        })}
      </ul>
    );
  } else {
    return <></>;
  }
};

/**
 * Nav component
 *
 * Nav component is for top Nav bar
 *
 * @param {object} props
 */
const Nav = function (props) {
  // const menuRef=useRef();

  const logo = () => {
    /**
     * return rendered view
     *
     */
    return (
      <span>
        {(() => {
          if (props.enable) {
            return (
              <>
                <img
                  className="va-m"
                  src="/toggle.svg"
                  alt="toggle"
                  onClick={props.navToggle}
                />
                &nbsp;&nbsp;&nbsp;
              </>
            );
          } else {
            return <></>;
          }
        })()}

        <span className="pr-vm si-mob t-mob" href="/">
          <img className="si-mob" alt="Logo" src="/logo.png" /> LMIS
        </span>
        <span className="pr-vm h-mob">
          <img src="/logo.png" alt="Logo" />{" "}
          <span>Logistics Management Information System </span>{" "}
          
        </span>
      </span>
    );
  };

  const CustomMainMenu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    /**
     * return rendered view
     *
     */
    return (
      <div className="wfp--main-navigation">
        <div
          className={
            "wfp--wrapper wfp--wrapper--width-mobile-full wfp--main-navigation__wrapper " +
            (props.className ? props.className : "")
          }
        >
          <div className="wfp--main-navigation__logo-wrapper">
            <button
              tabIndex="0"
              className="wfp--main-navigation__button wfp--btn wfp--btn--primary"
              type="button"
              onClick={() => {
                setMenuOpen(!menuOpen);
                props.setNavState("open");
              }}
            >
              Menu
            </button>
            <div className="wfp--main-navigation__logo">{props.logo}</div>
          </div>
          <ul
            className={
              "wfp--main-navigation__list " +
              (menuOpen ? "wfp--main-navigation__list--open" : "")
            }
          >
            {props.items.map((item, index) => {
              if (!item.show) return <span key={"nl" + index}></span>;
              return (
                <li
                  key={"nl" + index}
                  className={
                    "wfp--main-navigation__item " +
                    (item.className ? item.className : "")
                  }
                >
                  <div className="wfp--main-navigation__trigger">
                    {item.view}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };
  return (
    /**
     * return rendered view
     *
     */
    <CustomMainMenu
      setNavState={props.setNavState}
      logo={logo()}
      className={'trc-main-color'
      }
      items={[
        {
          view: (
            <>
              {props.eUser.actionLink === "form" ? (
                <Button
                  className="act-button"
                  onClick={(a) => {
                    props.eUser._action(a);
                  }}
                >
                  Submit
                </Button>
              ) : (
                <>
                 {/*<>{true || props.eUser._isSuperMin() ? (
                    <Link href="/emergencies/form/srf">
                      Create Service Request
                    </Link>
                  ) : (
                    <></>
                  )}
                  </>*/}
                </>
              )}
            </>
          ),
          show: true,
          className: "phone-hide",
        },
        {
          view: (
            <>
              {" "}
              {props.eUser && !props.eUser._isGuest() ? (
                <User
                  ellipsis
                  title={props.eUser.name ? props.eUser.name : "guest"}
                  name={props.eUser.name ? props.eUser.name : "guest"}
                />
              ) : (
                <></>
              )}
            </>
          ),
          show: true,
        },
        {
          view: (
            <>
              {props.route !== "login" ? (
                <Link
                  href={
                    props.eUser._isGuest() ? "/login" : "/action/auth/logout"
                  }
                >
                  {props.eUser._isGuest() ? "Login" : "Logout"}
                </Link>
              ) : null}
            </>
          ),
          show: true,
        },

        {
          view: (
            <Link
              onClick={() => {
                props.eUser._scanQR();
              }}
            >
              <FaQrcode />
              <Helper.Nbsp total={2} />
              Scan
            </Link>
          ),
          show: props.mobile,
        },
      ]}
    ></CustomMainMenu>
  );
};

export { Nav, Sidebar };
